@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Courgette|Open+Sans&display=swap');

$font-family1: 'Courgette', cursive;
$font-family2: 'Open Sans', sans-serif;

body {
    background-color: #ffffff;
    font-family: $font-family2;
}

.row {
    margin-right: 0px;
}

h1, h2, h3, h4 {
    font-family: $font-family1;
}

.btn-all, .btn-all:hover{
    border-radius:12px 0 12px 0;
    background: rgba(255, 255, 255, 0.5);
    border:none;
    color: #ffffff;
    font:24px $font-family2;
    padding:10px 15px 10px 15px;
    box-shadow:1px 1px 3px #999;
    text-decoration: none;
}

/***********************************HOME*******************************/
.container-home{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0)), url("/img/coquelicots-bouquet.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
}

.div-h1{
    padding-top: 40vh;
    margin-bottom: 40px;
}

.h1-home{
    color: white;
    font-size: 96px;
    text-shadow: 1px 1px 2px #082b34;
    border-bottom: 4px solid white;
    border-top: 4px solid white;
    margin: auto;
    width: fit-content;
}

/********************** WORK *************************************/

.work{
    background-position: bottom;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url("/img/hands.jpg");
    background-size: cover;
    text-align: left;
    color: whitesmoke;
    padding-bottom: 100px;
}

.wrapper {
    position: relative;
    margin: auto;
    overflow: auto;

    a{
        color: #ff832d;
    }

    a:hover{
        color: rgba(199, 64, 18, 0.69);
    }

    img{
        float: right;
        width: 400px;
        margin: 0 0 30px 30px;
    }

    .img-work{
        float: left !important;
        margin: 30px 30px 30px 0 !important;
    }
}

.p-work{
    font-size: 24px;
}

/**************************** SHOP *******************************/
.shop{
    background-position: bottom;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.82)), url("/img/oingt.jpg");
    background-size: cover;
    text-align: left;
    color: whitesmoke;
    padding-bottom: 100px;
}



/**************************** BLOG **********************************/
.blog {
    min-height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url("/img/coeur.jpg");
    background-size: cover;
    text-align: center;
    padding-bottom: 100px;
}

.blog-articles .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border: none;
    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
    }
    a {
        color: initial;
        &:hover {
            text-decoration: initial;
        }
    }
    .text-muted i {
        margin: 0 10px;
    }
}

/******************************** FOOTER ************************************************/
.footer{
    background: #222424;
    color:white;

    a {
        color: white;
        transition: color .2s;

        &:hover {
            text-decoration: none;
            color: #d7400a;
        }
    }

    .links{
        ul {list-style-type: none;}
    }
    .about-company{
        i{font-size: 25px;}
    }
    .location{
        i{font-size: 18px;}
    }
    .copyright p{border-top:1px solid rgba(255,255,255,.1);}
}

/****************** SEARCH BAR ***************************************/
.search {
    width: 100%;
    position: relative;
    display: flex;
}

.searchTerm{
    width: 200px;
    border: 3px solid #222424;
    border-right: none;
    padding: 5px;
    height: 40px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #222424;
}

.searchTerm:focus{
    color: #222424;
}

.searchButton {
    width: 40px;
    height: 40px;
    border: 1px solid #222424;
    background: #222424;
    text-align: center;
    color: #ffffff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}

.wrap{
    margin-bottom: 50px;
}

/********************** LEGAL MENTIONS *************************************/

.legal{
    background-position: bottom;
    background-color: #1d2124;
    background-size: cover;
    text-align: left;
    color: whitesmoke;
    padding-bottom: 100px;
}

/**************************** RESPONSIVE *********************************************/

@media (max-width: 575.98px) {
    .articles-title {
        font-size: 50px;
    }
    .h1-home {
        font-size: 40px !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .articles-title {
        font-size: 50px;
    }
    .h1-home {
        font-size: 40px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {

}
